import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import CommonLoader from './components/CommonLoader';
import './index.scss';
import './libs/i18n';


ReactDOM.render(
  <Suspense fallback={<CommonLoader />}>
    <Router>
      <App />
    </Router>
  </Suspense>, 
  document.getElementById('root')
);
