import React, { useState, useEffect } from 'react';
import AllGenresContainer from './AllGenresContainer';
import SeriesGenreTop from './SeriesGenreTop';


function SeriesAllGenres({ selectGenres }) {
  return (
    <AllGenresContainer selectGenres={selectGenres} genreTopEndpoint="/api/series/genretop" genreTopComponent={SeriesGenreTop} />
  );
}


export default SeriesAllGenres;
