import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuthContext } from '../libs/context';
import { useFormFields } from '../libs/hooks';
import { validateUsername, validatePassword } from '../libs/loginValidation';
import '../styles/Login.scss';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';


function Login() {
  const { t } = useTranslation();
  const { setAuthedUser } = useAuthContext();
  const [fields, handleFieldChange] = useFormFields({
    username: {value: '', error: '', validator: validateUsername},
    password: {value: '', error: '', validator: validatePassword}
  });
  
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertErrors, setAlertErrors] = useState([]);
  
  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setShowAlert(false);
    
    // Call to make sure that required fields are validated initially.
    handleFieldChange(event);
    
    const form = event.currentTarget;
    const valid = form.checkValidity();
    setIsValidated(true);
    
    if (!valid)
      return;
    
    setIsLoading(true);
    
    try {
      const loginData = { 
        username: fields.username.value, 
        password: fields.password.value
      };
      const response = await axios.post('/api/user/login', loginData);
      const { user } = response.data;
      setAuthedUser(user);
    } catch (err) {
      const errData = err.response.data;
      const { errors } = errData;
      console.error(errData);
      setAlertErrors(errors);
      setIsLoading(false);
      setShowAlert(true);
    }
  }
  
  return (
    <div className="ms-page-container">
      <div className="ms-form-container">
        <h1 className="ms-form-header">{t('Login')}</h1>
        <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} transition={false} dismissible>
          { alertErrors.map((e, i) => <div key={i}>{t(e)}</div>) }
        </Alert>
        <Form className="ms-form" onSubmit={handleSubmit} noValidate validated={isValidated}>
          <Form.Group controlId="username">
            <Form.Control 
              autoFocus 
              type="text" 
              placeholder={t('EnterUsername')} 
              value={fields.username.value} 
              onChange={handleFieldChange} />
            <Form.Control.Feedback type="invalid">
              { fields.username.error && t(fields.username.error) }
            </Form.Control.Feedback>
          </Form.Group>
        
          <Form.Group controlId="password">
            <Form.Control 
              type="password" 
              placeholder={t('EnterPassword')} 
              value={fields.password.value} 
              onChange={handleFieldChange} />
            <Form.Control.Feedback type="invalid">
            { fields.password.error && t(fields.password.error) }
            </Form.Control.Feedback>
          </Form.Group>
          
          <Button variant="primary" type="submit" disabled={isLoading}>
            { isLoading ? <Spinner as="span" animation="border" size="sm" /> : t('Login') }
          </Button>
          
          <p className="ms-form-forgot-password">{t('ForgotPasswordContactAdmin')}</p>
        </Form>
      </div>
    </div>
  );
}

export default Login;
