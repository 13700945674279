import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/MediaPreview.scss';

import Image from 'react-bootstrap/Image';


function MediaPreview({ mediaID, posterURL, progress = null, detailsModalComponent: DetailsModalComponent }) {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  function handleShowModal() {
    history.push('#');
    window.onpopstate = event => {
      handleCloseModal();
    };
    setShowModal(true);
  }
  
  function handleCloseModal() {
    window.onpopstate = null;
    setShowModal(false);
  }
  
  return (
    <div className="preview-container">
      <div className="preview-container-inner">
        <Image id={mediaID} className="preview-image" src={posterURL} onClick={handleShowModal} fluid />
        {typeof progress === 'number' && progress > 0 &&
          <div className="watch-progressbar-container">
            <div className="watch-progressbar" style={{ width: progress * 100 + '%' }}></div>
          </div>
        }
      </div>
      <DetailsModalComponent show={showModal} onHide={handleCloseModal} mediaID={mediaID} />
    </div>
  );
}

export default MediaPreview;
