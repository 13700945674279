import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteHScroll from './InfiniteHScroll';
import '../styles/GenreTopContainer.scss';


function GenreEmpty() {
  const { t } = useTranslation();
  return ( <div><p>{t('NoMediaInGenre')}</p></div> );
}


function GenreTopContainer({ title, initialData, getMoreMedia }) {
  const pageLimit = 10;
  
  return (
    <div className="genre-container">
      <div className="genre-header">
        <h2>{title}</h2>
      </div>
      <div className="genre-contents">
        <InfiniteHScroll 
          initialData={initialData} 
          pageSize={pageLimit} 
          pageStart={1} 
          loadMore={getMoreMedia} 
          emptyComponent={GenreEmpty} />
      </div>
    </div>
  );
}


export default GenreTopContainer;
