import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MediaPage from './MediaPage';
import SeriesAllGenres from './SeriesAllGenres';
import SeriesPreview from './SeriesPreview';


function Series() {
  const { t } = useTranslation();
  return (
    <MediaPage 
      title={t('Series')} 
      genresEndpoint='/api/series/genres' 
      searchEndpoint='/api/series/search' 
      genresComponent={SeriesAllGenres} 
      previewComponent={SeriesPreview} />
  );
}


export default Series;
