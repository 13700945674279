import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useCGet } from '../libs/hooks';
import Episode from './Episode';
import CommonLoader from './CommonLoader';
import '../styles/SeriesDetailsModal.scss';

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function SeriesDetailsModal({ mediaID, show, handleEpisodeClick = () => {}, ...props }) {
  const { t, i18n } = useTranslation();
  const [series, setSeries] = useState({});
  const [seriesLoaded, setSeriesLoaded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [season, setSeason] = useState(0);
  const [nextEpisode, setNextEpisode] = useState(null);
  const [getEpisodes, episodesLoading] = useCGet();
  const [episodes, setEpisodes] = useState([]);
  
  function isLoading() {
    return !seriesLoaded || !imgLoaded;
  }
  
  function fixDetailsLang(seriesData, lang) {
    seriesData.title = seriesData.title[lang];
    seriesData.plot = seriesData.plot[lang];
    seriesData.languages = seriesData.languages[lang].map((l, i) => <span className="mapped-data" key={`lang${i}`}>{l}</span>);
    seriesData.createdby = seriesData.createdby.map((c, i) => <span className="mapped-data" key={`creator${i}`}>{c}</span>);
    seriesData.directors = seriesData.directors.map((d, i) => <span className="mapped-data" key={`director${i}`}>{d}</span>);
    seriesData.writers = seriesData.writers.map((w, i) => <span className="mapped-data" key={`writer${i}`}>{w}</span>);
    seriesData.cast = seriesData.cast.map((a, i) => <span className="mapped-data" key={`cast${i}`}>{a}</span>);
    seriesData.genres = Object.values(seriesData.genres).map((g, i) => <span className="mapped-data" key={`genre${i}`}>{g.name[lang]}</span>);
    seriesData.backdrop = seriesData.backdrop || seriesData.poster;
    seriesData.firstyear = new Date(seriesData.firstdate).getFullYear();
    seriesData.latestyear = new Date(seriesData.latestdate).getFullYear();
    seriesData.seasons = seriesData.seasons.filter(s => s.available);
    seriesData.seasons.sort((s1, s2) => s1.number - s2.number);
    seriesData.seasonNumbers = seriesData.seasons.map(s => s.number);
    seriesData.imdbrating = typeof seriesData.imdbrating == "number" ? seriesData.imdbrating.toFixed(1) : seriesData.imdbrating;
  }
  
  async function getSeriesDetails() {
    try {
      const seriesResponse = await axios.get(`/api/series/details/${mediaID}`);
      const seriesData = seriesResponse.data;
      fixDetailsLang(seriesData, i18n.language);
      setSeries(seriesData);
      setSeason(seriesData.seasonNumbers[0]);
      setSeriesLoaded(true);
      getNextEpisode(seriesData);
    } catch (err) {
      console.error(err);
    }
  }

  async function getNextEpisode(seriesData) {
    try {
      const nextEpisodeResponse = await axios.get(`/api/series/next/${mediaID}`);

      if (!nextEpisodeResponse.data) 
        return;
      
      const nextEpisodeData = {
        ...nextEpisodeResponse.data,
        fallbackImage: seriesData.backdrop, 
        seriesMediaID: mediaID, 
        seriesTitle: seriesData.title 
      };
      
      setNextEpisode(nextEpisodeData);
    } catch (err) {
      console.error(err);
    }
  }
  
  async function getSeasonEpisodes(seasonNumber) {
    try {
      const response = await getEpisodes(`/api/series/episodes/${mediaID}/${seasonNumber}`);
      const episodeData = response.data.filter(e => e.available);
      setEpisodes(episodeData);
    } catch (err) {
      console.log('EpisodesError:', err.message || err);
    }
  }
  
  function handleSeasonChange(event) {
    const seasonNumber = event.target.value;
    setSeason(seasonNumber);
  }
  
  useEffect(() => {
    if (show && !seriesLoaded) {
      getSeriesDetails();
    }
    
    // When modal closes, season selection dropdown resets, but not the state.
    // Have to reset state manually.
    if (!show && seriesLoaded) {
      setSeason(series.seasonNumbers[0]);
    }
  }, [show]);
  
  useEffect(() => {
    if (season >= 1) {
      getSeasonEpisodes(season);
    }
  }, [season]);
  
  return (
    <Modal className="preview-modal" {...props} show={show} size="lg" centered animation={true}>
      <CommonLoader style={{ display: isLoading() ? 'block' : 'none' }} />
      <Modal.Body style={{ display: !isLoading() ? 'block' : 'none' }}>
        <Container className="preview-modal-container" fluid>
          <Row className="preview-modal-backdrop">
            <Image id={`${mediaID}-backdrop`} 
                   onLoad={() => setImgLoaded(true)} 
                   src={series.backdrop} 
                   fluid />
            <div className="back-arrow" onClick={props.onHide}>
              <i className="fas fa-arrow-circle-left"></i>
            </div>
            <div className="on-backdrop-details">
              <i className="fas fa-star"></i>
              <span className="media-imdbrating">{ series.imdbrating }</span>
              <i className="fas fa-circle"></i>
              <span className="media-year">{ `${series.firstyear}‒${series.inproduction ? '' : series.latestyear}` }</span>
              <i className="fas fa-circle"></i>
              <span className="media-languages">{ series.languages }</span>
            </div>
          </Row>
          {nextEpisode &&
            <Row className="play-button-container">
              <Link to={{ pathname: '/watchepisode', state: nextEpisode }} onClick={handleEpisodeClick}>
                <Button variant="secondary">
                  <span className="play-button-text">{`${t('ContinueWatching')}: ${nextEpisode.number.map(n => `S${nextEpisode.seasonNum.toString().padStart(2, '0')}E${n.toString().padStart(2, '0')}`).join(' & ')}`}</span>
                </Button>
              </Link>
            </Row>
          }
          <Row className="media-title">
            <span>{ series.title }</span>
          </Row>
          <Row className="media-plot">{ series.plot || t('NoPlot') }</Row>
          {series.createdby && series.createdby.length > 0 &&
            <Row className="media-people-genres series-createdby">
              <span className="details-section-title">{t('CreatedByTitle')}:</span>
              { series.createdby }
            </Row>
          }
          {series.directors && series.directors.length > 0 &&
            <Row className="media-people-genres media-directors">
              <span className="details-section-title">{t('DirectorsTitle')}:</span>
              { series.directors }
            </Row>
          }
          {series.writers && series.writers.length > 0 &&
            <Row className="media-people-genres media-writers">
              <span className="details-section-title">{t('WritersTitle')}:</span>
              { series.writers }
            </Row>
          }
          {series.cast && series.cast.length > 0 &&
            <Row className="media-people-genres media-cast">
              <span className="details-section-title">{t('CastTitle')}:</span>
              { series.cast }
            </Row>
          }
          <Row className="media-people-genres media-genres">
            <span className="details-section-title">{t('GenresTitle')}:</span>
            { series.genres }
          </Row>
          {series.seasons && 
            (series.seasons.length === 0 
              ? <div className="no-seasons">{t('NoSeasonsAvailable')}</div>
              : <>
                  <Row className="series-seasons">
                    <div className="series-episodes-title">{t('Episodes')}</div>
                    <div className="series-episodes-select">
                      <Form onSubmit={e => e.preventDefault()}>
                        <Form.Control as="select" onChange={handleSeasonChange} defaultValue={series.seasonNumbers[0]}>
                          {series.seasonNumbers.map(sn => 
                            <option key={`season${sn}`} value={sn}>{`${t('Season')} ${sn}`}</option>
                          )}
                        </Form.Control>
                      </Form>
                    </div>
                  </Row>
                  <hr className="seasons-episodes-divider" />
                  <Row className="series-episodes">
                    {episodesLoading
                      ? <CommonLoader />
                      : episodes.length === 0 
                          ? <h4>{t('NoEpisodesAvailable')}</h4>
                          : episodes.map(e => 
                              <Episode 
                                key={`episode${e.mediaid}`} 
                                epData={{ 
                                  ...e, 
                                  fallbackImage: series.backdrop, 
                                  seasonNum: season, 
                                  seriesMediaID: mediaID, 
                                  seriesTitle: series.title 
                                }} 
                                handleClick={handleEpisodeClick}
                              />
                            )
                    }
                  </Row>
                </>
            )
          }
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default SeriesDetailsModal;
