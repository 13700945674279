import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../libs/context';


function PublicRoute({
  component: Component, 
  shouldRedirect = false, 
  location: { 
    state: { 
      redirect = '/'
    } = {}
  } = {}, 
  ...rest }) 
{
  const { authedUser } = useAuthContext();
  
  return (
    <Route {...rest} render={props => (
      authedUser && shouldRedirect
        ? <Redirect to={redirect} />
        : <Component {...props} />
    )} />
  );
}

export default PublicRoute;
