import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import debounce from 'lodash/debounce';
import SearchResults from './SearchResults';
import '../styles/MediaPage.scss';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';


function MediaPage({ title, genresEndpoint, searchEndpoint, genresComponent: AllGenres, previewComponent: Preview }) {
  const { t, i18n } = useTranslation();
  const searchBoxRef = useRef(null);
  const [genres, setGenres] = useState([]);
  const [genreChoice, setGenreChoice] = useState({name: t('AllGenres'), gid: -1});
  // The query input by the user into the search field. Will change 
  // immediately each time the user types something in the field.
  const [queryFieldVal, setQueryFieldVal] = useState('');
  // The actual search query that will be sent to the SearchResults component.
  // The onChange handler on the search input field will be debounced, so this 
  // value will be updated 300ms after the user stops typing in the field.
  const [searchQuery, setSearchQuery] = useState('');
  // To keep a function in state, since state can be set using a function, we 
  // need to set it to an anonymous function returning the function we want to set.
  const [debouncedHandleSearch, setDebouncedHandleSearch] = useState(() => () => {});
  
  async function getGenres() {
    try {
      const response = await axios.get(genresEndpoint);
      setGenres(response.data);
    } catch (err) {
      console.error(err.response.data);
    }
  }
  
  useEffect(() => {
    setDebouncedHandleSearch(() => debounce(setSearchQuery, 300));
    getGenres();
  }, []);
  
  function handleGenreClick(gidStr, event) {
    const gid = parseInt(gidStr);
    
    if (gid === genreChoice.gid) {
      return;
    }
    
    setGenreChoice({
      name: event.target.textContent,
      gid: gid
    });
  }
  
  function clearSearch(event) {
    setQueryFieldVal('');
    searchBoxRef.current.focus();
  }
  
  function handleQueryChange(event) {
    setQueryFieldVal(event.target.value);
    debouncedHandleSearch.cancel();
    debouncedHandleSearch(event.target.value);
  }
  
  return (
    <div className="ms-page-container">
      <div className="media-page-header">
        <div className="media-page-title">
          <h1>{title}</h1>
        </div>
        <div className="media-page-filtering">
          <div className={`media-page-genres ${queryFieldVal ? 'genres-disabled' : ''}`}>
            <DropdownButton title={genreChoice.name}>
              <Dropdown.Item eventKey={-1} onSelect={handleGenreClick}>{t('AllGenres')}</Dropdown.Item>
              {genres.map(g => <Dropdown.Item key={g.gid} eventKey={g.gid} onSelect={handleGenreClick}>{g.name[i18n.language]}</Dropdown.Item>)}
            </DropdownButton>
          </div>
          <div className="media-page-search">
            <Form onSubmit={e => e.preventDefault()} inline>
              <Form.Control ref={searchBoxRef} 
                            type="text" 
                            placeholder={t('SearchPlaceholder')} 
                            value={queryFieldVal} 
                            onChange={handleQueryChange} />
              <span className="clear-search" 
                    onClick={clearSearch}
                    style={{display: queryFieldVal ? 'block' : 'none' }} >
                <i className="fas fa-times"></i>
              </span>
            </Form>
          </div>
        </div>
      </div>
      <div className="media-page-list">
        { !queryFieldVal 
          ? <AllGenres selectGenres={genreChoice.gid !== -1 ? [genreChoice.gid] : []} /> 
          : <SearchResults query={searchQuery} searchEndpoint={searchEndpoint} previewComponent={Preview} /> }
      </div>
    </div>
  );
}


export default MediaPage;
