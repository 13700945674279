import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAuthContext } from '../libs/context';
import { useFormFields } from '../libs/hooks';
import { validateOldPassword, validateNewPassword, validateConfPassword } from '../libs/changePassValidation';
import '../styles/ChangePassword.scss';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';


function ChangePassword() {
  const { t } = useTranslation();
  const { setAuthedUser } = useAuthContext();
  const history = useHistory();
  
  const [fields, handleFieldChange] = useFormFields({
    oldPassword:  {value: '', error: '', validator: validateOldPassword},
    newPassword:  {value: '', error: '', validator: validateNewPassword},
    confPassword: {value: '', error: '', validator: validateConfPassword}
  });
  
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertErrors, setAlertErrors] = useState([]);
  
  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setShowAlert(false);
    
    // Call to make sure that required fields are validated initially.
    handleFieldChange(event);
    
    const form = event.target;
    const valid = form.checkValidity();
    setIsValidated(true);
    
    if (!valid)
      return;
    
    setIsLoading(true);
    
    try {
      const passwordData = { 
        oldPassword: fields.oldPassword.value, 
        newPassword: fields.newPassword.value
      };
      await axios.post('/api/user/password', passwordData);
      setAuthedUser(null);
      history.push('/'); // Set redirect URL for PrivateRoute
    } catch (err) {
      const errData = err.response.data;
      const { errors } = errData;
      console.error(errData);
      setAlertErrors(errors);
      setIsLoading(false);
      setShowAlert(true);
    }
  }
  
  return (
    <div className="change-password-container ms-form-container">
      <h2 className="ms-form-header">{t('ChangePassword')}</h2>
      <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} transition={false} dismissible>
        { alertErrors.map((e, i) => <div key={i}>{t(e)}</div>) }
      </Alert>
      <Form className="ms-form" onSubmit={handleSubmit} noValidate validated={isValidated}>
        <Form.Group controlId="oldPassword">
          <Form.Control 
            autoFocus 
            type="password" 
            placeholder={t('EnterOldPassword')} 
            value={fields.oldPassword.value} 
            onChange={handleFieldChange} />
          <Form.Control.Feedback type="invalid">
            { fields.oldPassword.error && t(fields.oldPassword.error) }
          </Form.Control.Feedback>
        </Form.Group>
      
        <Form.Group controlId="newPassword">
          <Form.Control 
            type="password" 
            placeholder={t('EnterNewPassword')} 
            value={fields.newPassword.value} 
            onChange={handleFieldChange} />
          <Form.Control.Feedback type="invalid">
            { fields.newPassword.error && t(fields.newPassword.error) }
          </Form.Control.Feedback>
        </Form.Group>
        
        <Form.Group controlId="confPassword">
          <Form.Control 
            type="password" 
            placeholder={t('ConfirmNewPassword')} 
            value={fields.confPassword.value} 
            onChange={handleFieldChange} />
          <Form.Control.Feedback type="invalid">
            { fields.confPassword.error && t(fields.confPassword.error) }
          </Form.Control.Feedback>
        </Form.Group>
        
        <p className="subtext">
          {t('AfterChangingPassword')}
        </p>
        
        <Button variant="primary" type="submit" disabled={isLoading}>
          { isLoading ? <Spinner as="span" animation="border" size="sm" /> : t('ChangePassword') }
        </Button>
      </Form>
      
    </div>
  );
}

export default ChangePassword;
