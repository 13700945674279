import React from 'react';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';
import ChangePassword from './ChangePassword';
import AppDownload from './AppDownload';
import '../styles/Settings.scss';


function Settings() {
  const { t } = useTranslation();
  
  return (
    <div className="settings ms-page-container">
      <div className="settings-header">
        <h1>{t('Settings')}</h1>
      </div>
      <div className="setting-container setting-language">
        <ChangeLanguage />
      </div>
      <div className="setting-container setting-password">
        <ChangePassword />
      </div>
      <div className="setting-container setting-app">
        <AppDownload />
      </div>
    </div>
  );
}


export default Settings;
