import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GenreTopContainer from './GenreTopContainer';
import MoviePreview from './MoviePreview';


function MoviesGenreTop({ genre }) {
  const { t, i18n } = useTranslation();
  const pageLimit = 10;
  
  function moviesToPreviews(movies) {
    return movies.map(m => 
      <MoviePreview key={m.mediaid} mediaID={m.mediaid} posterURL={m.poster} progress={m.progress} />
    );
  }
  
  async function getMoreMovies(page) {
    const skip = pageLimit * page;
    
    try {
      const response = await axios.get(`/api/movies/findByGenre/${genre.gid}?skip=${skip}&limit=${pageLimit}`);
      const moreMovies = moviesToPreviews(response.data);
      return moreMovies;
    } catch (err) {
      console.error(err);
      alert(t('FailedToLoadMedia'));
      throw err;
    }
  }
  
  return (
    <GenreTopContainer 
      title={genre.name[i18n.language]} 
      initialData={moviesToPreviews(genre.movies)} 
      getMoreMedia={getMoreMovies} />
  );
}


export default MoviesGenreTop;
