// All validation functions return the errors in form of a i18n key, so that 
// they can be appropriately translated in the form in which they are used.


function validateUsername(form) {
  const username = form.elements.username;
  let error;
  
  if (username.value === '') {
    error = 'UsernameRequired';
  } else {
    error = '';
  }
  
  username.setCustomValidity(error);
  return error;
}


function validatePassword(form) {
  const password = form.elements.password;
  let error;
  
  if (password.value === '') {
    error = 'PasswordRequired';
  } else {
    error = '';
  }
  
  password.setCustomValidity(error);
  return error;
}


export { 
  validateUsername, 
  validatePassword
};
