import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReadMoreLess from './ReadMoreLess';
import '../styles/Episode.scss';

import Image from 'react-bootstrap/Image';


function Episode({ epData, handleClick = () => {}, ...props }) {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="series-episode" {...props}>
      <div className="series-episode-details">
        <div className="series-episode-image">
          <Image src={epData.image || epData.fallbackImage} />
          {typeof epData.progress === 'number' && epData.progress > 0 &&
            <div className="watch-progressbar-container">
              <div className="watch-progressbar" style={{ width: epData.progress * 100 + '%' }}></div>
            </div>
          }
          <Link to={{ pathname: '/watchepisode', state: epData }} onClick={handleClick}>
            <i className="far fa-play-circle episode-play-btn"></i>
          </Link>
        </div>
        <div className="series-episode-info">
          <div className="series-episode-title-runtime">
            <div className="series-episode-title">{epData.number.join(' & ')}. &nbsp; {epData.title[i18n.language]}</div>
            <div className="series-episode-runtime">{`${epData.runtime} min`}</div>
          </div>
          <ReadMoreLess maxHeight={110}>
            <div className="series-episode-plot">{epData.plot[i18n.language] || t('NoPlot')}</div>
          </ReadMoreLess>
        </div>
      </div>
      <hr className="series-episode-divider" />
    </div>
  );
}


export default Episode;
