// All validation functions return the errors in form of a i18n key, so that 
// they can be appropriately translated in the form in which they are used.


function validateUsername(form) {
  const username = form.elements.username;
  let error;
  
  if (username.value.length < 3 || username.value.length > 15) {
    error = 'UsernameMin3Max15';
  } else {
    error = '';
  }
  
  username.setCustomValidity(error);
  return error;
}


function validatePassword(form) {
  const password = form.elements.password;
  let error;
  
  if (password.value.length < 8) {
    error = 'PasswordMin8';
  } else {
    error = '';
  }
  
  password.setCustomValidity(error);
  return error;
}


function validateConfPassword(form) {
  const password = form.elements.password;
  const confPass = form.elements.confPassword;
  let error;
  
  if (confPass.value !== password.value) {
    error = 'PasswordsDontMatch';
  } else {
    error = '';
  }
  
  confPass.setCustomValidity(error);
  return error;
}


export { 
  validateUsername, 
  validatePassword,
  validateConfPassword
};
