import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import CommonLoader from './CommonLoader';
import '../styles/InfiniteHScroll.scss';


function InfiniteHScroll({ initialData, pageStart, pageSize, loadMore, emptyComponent: EmptyComponent }) {
  const [data, setData] = useState({ 
    items: initialData, 
    nextPage: pageStart, 
    loading: false, 
    hasMore: initialData.length >= pageSize
  });
  
  const [scroll, setScroll] = useState({
    isMin: true, 
    isMax: true
  });
  
  const [applyLoadOverlay, setApplyLoadOverlay] = useState(false);
  const itemsContainerRef = useRef(null);
  
  async function loadNextPage() {
    setData(currentData => ({ ...currentData, loading: true }));
    
    const loadOverlayTimeout = setTimeout(() => setApplyLoadOverlay(true), 300);
    const moreItems = await loadMore(data.nextPage);
    
    clearTimeout(loadOverlayTimeout);
    setApplyLoadOverlay(false);
    
    if (moreItems.length > 0) {
      setScroll(vals => ({ ...vals, isMax: false }));
      setData(currentData => ({ 
        ...currentData, 
        items: [...currentData.items, ...moreItems], 
        nextPage: currentData.nextPage + 1, 
        loading: false
      }));
    } else {
      setData(currentData => ({ 
        ...currentData, 
        loading: false, 
        hasMore: false
      }));
    }
  }
  
  function handleScroll() {
    const itemsContainer = itemsContainerRef.current;
    calcAndSetScroll(itemsContainer);
  }
  
  function calcAndSetScroll(itemsContainer) {
    const scrollbarMaxVal = itemsContainer.scrollWidth - itemsContainer.clientWidth;
    const scrollbarIsMin = itemsContainer.scrollLeft <= 0;
    const scrollbarIsMax = itemsContainer.scrollLeft >= scrollbarMaxVal;
    
    setScroll(vals => ({ isMin: scrollbarIsMin, isMax: scrollbarIsMax && !data.hasMore }));
  }
  
  function handleMoreLeft(event) {
    const itemsContainer = itemsContainerRef.current;
    const newScrollPos = itemsContainer.scrollLeft - itemsContainer.clientWidth * 0.8;
    
    itemsContainer.scrollTo({
      left: newScrollPos,
      behavior: 'smooth'
    });
  }
  
  async function handleMoreRight(event) {
    const itemsContainer = itemsContainerRef.current;
    const newScrollPos = itemsContainer.scrollLeft + itemsContainer.clientWidth * 0.8;
    const scrollbarMaxVal = itemsContainer.scrollWidth - itemsContainer.clientWidth;
    
    if (newScrollPos >= scrollbarMaxVal) {
      try {
        await loadNextPage();
      } catch {
        return;
      }
    }
    
    itemsContainer.scrollTo({
      left: newScrollPos,
      behavior: 'smooth'
    });
  }
  
  useEffect(() => {
    const itemsContainer = itemsContainerRef.current;
    
    // itemsContainer will be null when only EmptyComponent is rendered
    if (!itemsContainer) {
      return;
    }
    
    calcAndSetScroll(itemsContainer);
    
    const handleResize = debounce(() => calcAndSetScroll(itemsContainer), 100);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, []);
  
  return (
    data.items.length > 0 ? (
      <div className="iss-container">
        <div className="iss-more-left" 
             style={{display: !scroll.isMin ? 'block' : 'none' }} 
             onClick={!data.loading ? handleMoreLeft : null}>
          <i className="fas fa-chevron-circle-left"></i>
        </div>
        <div className="iss-more-right" 
             style={{display: !scroll.isMax || data.hasMore ? 'block' : 'none' }} 
             onClick={!data.loading ? handleMoreRight : null}>
          <i className="fas fa-chevron-circle-right"></i>
        </div>
        <div className="iss-loading-overlay" style={{display: applyLoadOverlay ? 'block' : 'none' }}>
          <CommonLoader />
        </div>
        <div ref={itemsContainerRef} className="iss-items-container" onScroll={debounce(handleScroll, 50)}>
          {data.items}
        </div>
      </div>
    ) : (
      <EmptyComponent />
    )
  );
}


export default InfiniteHScroll;
