import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import { useAuthContext } from '../libs/context';
import '../styles/MixloNavbar.scss';
import MixloLogo from '../images/mixlo_logo.png';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Alert from 'react-bootstrap/Alert';


function NavbarUnathed() {
  const { t, i18n } = useTranslation();
  
  return (
    <Navbar bg="dark" variant="dark" expand="sm" collapseOnSelect>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <LinkContainer to='/'>
        <Navbar.Brand>
          <img alt="Mixlo" src={MixloLogo} />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav>
          <LinkContainer to="/login">
            <Nav.Link active={false}>{t('Login')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/register">
            <Nav.Link active={false}>{t('Register')}</Nav.Link>
          </LinkContainer>
          <NavDropdown title={t('Language')} className="language-dropdown">
            <NavDropdown.Item active={false} onClick={() => i18n.changeLanguage('en-US')}>{t('English')}</NavDropdown.Item>
            <NavDropdown.Item active={false} onClick={() => i18n.changeLanguage('sv-SE')}>{t('Swedish')}</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}


function NavbarAuthed() {
  const { t, i18n } = useTranslation();
  const { authedUser, setAuthedUser } = useAuthContext();
  const history = useHistory();

  async function handleLogout() {
    try {
      await axios.post('/api/user/logout');
      setAuthedUser(null);
      history.push('/'); // Set redirect URL for PrivateRoute
    } catch (err) {
      console.error(err.response.data);
      alert(t('FailedToLogOutCurrentSession'));
    }
  }
  
  function userTitleIcon(authedUser) {
    return (
      <div className="user-profile">
        <span className="user-name">{authedUser}</span>
      </div>
    );
  }
  
  return (
    <>
      <Alert variant="success" className='mb-0'>{t('AppReleased')}</Alert>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Navbar.Toggle aria-controls="navbar-nav-left" />
        <LinkContainer to='/'>
          <Navbar.Brand>
            <img alt="Mixlo" src={MixloLogo} />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Collapse id="ms-navbar-left">
          <Nav className="ms-nav-left">
            <LinkContainer className="ms-nav-left-link" to="/movies">
              <Nav.Link active={false}>{t('Movies')}</Nav.Link>
            </LinkContainer>
            <LinkContainer className="ms-nav-left-link" to="/series">
              <Nav.Link active={false}>{t('Series')}</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
        <Nav className="ms-nav-profile ml-auto">
          <NavDropdown title={userTitleIcon(authedUser)} className="user-dropdown">
            <LinkContainer to="/settings">
              <NavDropdown.Item active={false}>{t('Settings')}</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="#">
              <NavDropdown.Item onClick={handleLogout}>{t('Logout')}</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Navbar>
    </>
  );
}


function MixloNavbar() {
  const { authedUser } = useAuthContext();
  return (authedUser ? <NavbarAuthed /> : <NavbarUnathed />);
}


export default withRouter(MixloNavbar);
