import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ReadMoreLess.scss';


function ReadMoreLess({ maxHeight, children }) {
  const [useToggle, setUseToggle] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const divRef = useRef(null);
  
  function toggle() {
    setShowMore(showMore => !showMore);
  }
  
  useEffect(() => {
    const div = divRef.current;
    const initialOverflow = div.clientHeight > maxHeight;
    setUseToggle(initialOverflow);
    setShowMore(!initialOverflow);
  }, []);
  
  useEffect(() => {
    const div = divRef.current;
    div.style.maxHeight = showMore ? 'none' : `${maxHeight}px`;
  }, [showMore]);
  
  return (
    <div ref={divRef} className={`readmoreless ${!showMore ? 'readmoreless-less' : ''}`} onClick={useToggle ? toggle : null}>
      { children }
      <div className="readmoreless-overlay" style={{display: useToggle && !showMore ? 'block' : 'none' }}>
        <div className="readmoreless-view-more">
          <i className="fas fa-chevron-down"></i>
        </div>
      </div>
    </div>
  );
}


export default ReadMoreLess;
