import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Home from './Home';
import Movies from './Movies';
import Series from './Series';
import Login from './Login';
import Register from './Register';
import Settings from './Settings';
import NotFound from './NotFound';
// Lazy load shaka-player module
const WatchMovie = lazy(() => import('./WatchMovie'));
const WatchEpisode = lazy(() => import('./WatchEpisode'));


function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/movies" component={Movies} />
      <PrivateRoute exact path="/series" component={Series} />
      <PublicRoute exact path="/login" component={Login} shouldRedirect />
      <PublicRoute exact path="/register" component={Register} shouldRedirect />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/watchmovie" component={WatchMovie} />
      <PrivateRoute exact path="/watchepisode" component={WatchEpisode} />
      <Route component={NotFound} />
    </Switch>
  );
}


export default Routes;
