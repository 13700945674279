import { useState, useEffect, useRef } from 'react';
import axios from 'axios';


function useFormFields(initialState) {
  const [fields, setFields] = useState(initialState);

  function handleFieldChange(event) {
    const form = event.target.closest('form');
    const newFields = {};
    
    for (const f in fields) {
      newFields[f] = {
        ...fields[f],
        error: fields[f].validator(form)
      }
    }
    
    if (event.target.id in newFields)
        newFields[event.target.id].value = event.target.value;
    
    setFields(newFields);
  }

  return [fields, handleFieldChange];
}


/* Cancellable GET request (wrapper around axios.get)
   - First return value is a wrapper around the axios get method.
   - Third return value is a variable indicating that the request is still in progress.*/
function useCGet() {
  const [loading, setLoading] = useState(false);
  const cancelSourceRef = useRef(null);
  const mountedRef = useRef(false);
  
  useEffect(() => {
    mountedRef.current = true;
    
    return () => { 
      mountedRef.current = false;
      if (cancelSourceRef.current) {
        cancelSourceRef.current.cancel();
      }
    };
  }, []);
  
  // Parameters 'url' and 'config' are directly passed into axios.get()
  async function get(url, config = {}) {
    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel();
    }
    
    const ts = axios.CancelToken.source();
    cancelSourceRef.current = ts;
    
    if (mountedRef.current) {
      setLoading(true);
    }
    
    try {
      const response = await axios.get(url, { cancelToken: ts.token, ...config });
      if (mountedRef.current) {
        setLoading(false);
        return response;
      } else {
        throw { message: 'UNMOUNTED' };
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        // Request is only cancelled if it is overridden by a new request,
        // hence, we don't call setLoading(false) here.
        throw { message: 'CANCELLED' };
      } else {
        if (mountedRef.current) {
          setLoading(false);
        }
        throw err;
      }
    }
  }
  
  return [get, loading];
}


export { useFormFields, useCGet };
