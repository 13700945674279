import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthContext } from '../libs/context';


function PrivateRoute({ component: Component, ...rest }) {
  const { authedUser } = useAuthContext();
  const location = useLocation();
  
  return (
    <Route {...rest} render={props => (
      !authedUser
        ? <Redirect to={{pathname: '/login', state: {redirect: location}}} />
        : <Component {...props} />
    )} />
  );
}

export default PrivateRoute;
