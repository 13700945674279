import React from 'react';
import { useTranslation } from 'react-i18next';


function NotFound() {
  const { t } = useTranslation();
  
  return (
    <div className="ms-page-container">
      <h1>{t('PageNotFound')}</h1>
    </div>
  );
}

export default NotFound;
