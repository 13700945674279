import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import InfiniteHScroll from './InfiniteHScroll';
import MediaPreview from './MediaPreview';
import MovieDetailsModal from './MovieDetailsModal';
import SeriesDetailsModal from './SeriesDetailsModal';
import CommonLoader from './CommonLoader';
import '../styles/Home.scss';


function Home() {
  const { t } = useTranslation();
  const pageLimit = 10;
  const [media, setMedia] = useState({
    newMedia: null, 
    topMovies: null, 
    topSeries: null, 
    loading: true
  });

  function mediaToPreview(mediaItem, detailsModalComponent) {
    return (
      <MediaPreview 
        key={mediaItem.mediaid} 
        mediaID={mediaItem.mediaid} 
        posterURL={mediaItem.poster} 
        progress={mediaItem.progress}
        detailsModalComponent={detailsModalComponent} />
    );
  }
  
  function mediaToPreviews(media, detailsModalComponent) {
    return media.map(m => mediaToPreview(m, detailsModalComponent));
  }
  
  function mediaToPreviewsFromType(media) {
    return media.map(m => {
      switch (m.type) {
        case 'movie' : return mediaToPreview(m, MovieDetailsModal);
        case 'series': return mediaToPreview(m, SeriesDetailsModal);
        default: return null;
      }
    }).filter(m => m); // Clears any null entries, i.e. any entries of neither type 'movie' nor 'series'.
  }

  async function getMoreUnfinishedMedia(page) {
    const skip = pageLimit * page;

    try {
      const response = await axios.get(`/api/media/unfinished?skip=${skip}&limit=${pageLimit}`);
      const moreUnfinishedMedia = mediaToPreviewsFromType(response.data);
      return moreUnfinishedMedia;
    } catch (err) {
      console.error(err);
      alert(t('FailedToLoadMedia'));
      throw err;
    }
  }
  
  async function getMoreNewMedia(page) {
    const skip = pageLimit * page;
    
    try {
      const response = await axios.get(`/api/media/new?skip=${skip}&limit=${pageLimit}`);
      const moreMedia = mediaToPreviewsFromType(response.data);
      return moreMedia;
    } catch (err) {
      console.error(err);
      alert(t('FailedToLoadMedia'));
      throw err;
    }
  }
  
  async function getMoreTopMovies(page) {
    const skip = pageLimit * page;
    
    try {
      const response = await axios.get(`/api/movies/top?skip=${skip}&limit=${pageLimit}`);
      const moreMovies = mediaToPreviews(response.data, MovieDetailsModal);
      return moreMovies;
    } catch (err) {
      console.error(err);
      alert(t('FailedToLoadMedia'));
      throw err;
    }
  }
  
  async function getMoreTopSeries(page) {
    const skip = pageLimit * page;
    
    try {
      const response = await axios.get(`/api/series/top?skip=${skip}&limit=${pageLimit}`);
      const moreSeries = mediaToPreviews(response.data, SeriesDetailsModal);
      return moreSeries;
    } catch (err) {
      console.error(err);
      alert(t('FailedToLoadMedia'));
      throw err;
    }
  }
  
  useEffect(() => {
    async function getMedia() {
      try {
        const unfinishedMedia = await getMoreUnfinishedMedia(0);
        const newMedia = await getMoreNewMedia(0);
        const topMovies = await getMoreTopMovies(0);
        const topSeries = await getMoreTopSeries(0);
        
        setMedia(currentMedia => ({
          ...currentMedia, 
          unfinishedMedia: unfinishedMedia,
          newMedia: newMedia, 
          topMovies: topMovies, 
          topSeries: topSeries,
          loading: false
        }));
      } catch {
        return;
      }
    }
    
    getMedia();
  }, []);
  
  return (
    media.loading ? (
      <CommonLoader />
    ) : (
      <div className="ms-home ms-page-container">
        {media.unfinishedMedia.length !== 0 &&
          <div className="unfinished-media-container media-container">
            <div className="media-header">
              <h2>{t('ContinueWatching')}</h2>
            </div>
            <div className="media-contents">
              <InfiniteHScroll 
                initialData={media.unfinishedMedia} 
                pageSize={pageLimit} 
                pageStart={1} 
                loadMore={getMoreUnfinishedMedia} 
                emptyComponent={() => <div><h4>{t('NoMediaFound')}</h4></div>} />
            </div>
          </div>
        }
        {media.newMedia.length !== 0 &&
          <div className="new-media-container media-container">
            <div className="media-header">
              <h2>{t('NewMoviesAndSeries')}</h2>
            </div>
            <div className="media-contents">
              <InfiniteHScroll 
                initialData={media.newMedia} 
                pageSize={pageLimit} 
                pageStart={1} 
                loadMore={getMoreNewMedia} 
                emptyComponent={() => <div><h4>{t('NoMediaFound')}</h4></div>} />
            </div>
          </div>
        }
        <div className="top-movies-container media-container">
          <div className="media-header">
            <h2>{t('TopMovies')}</h2>
          </div>
          <div className="media-contents">
            <InfiniteHScroll 
              initialData={media.topMovies} 
              pageSize={pageLimit} 
              pageStart={1} 
              loadMore={getMoreTopMovies} 
              emptyComponent={() => <div><h4>{t('NoMediaFound')}</h4></div>} />
          </div>
        </div>
        <div className="top-series-container media-container">
          <div className="media-header">
            <h2>{t('TopSeries')}</h2>
          </div>
          <div className="media-contents">
            <InfiniteHScroll 
              initialData={media.topSeries} 
              pageSize={pageLimit} 
              pageStart={1} 
              loadMore={getMoreTopSeries} 
              emptyComponent={() => <div><h4>{t('NoMediaFound')}</h4></div>} />
          </div>
        </div>
      </div>
    )
  );
}


export default Home;
