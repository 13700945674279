import React, { useState } from 'react';
import MediaPreview from './MediaPreview';
import MovieDetailsModal from './MovieDetailsModal';


function MoviePreview({ mediaID, posterURL, progress = null }) {
  return (
    <MediaPreview mediaID={mediaID} posterURL={posterURL} progress={progress} detailsModalComponent={MovieDetailsModal} />
  );
}

export default MoviePreview;
