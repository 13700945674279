import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import CommonLoader from './CommonLoader';
import '../styles/MovieDetailsModal.scss';

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function MovieDetailsModal({ mediaID, show, ...props }) {
  const { t, i18n } = useTranslation();
  const [movie, setMovie] = useState({});
  const [movieLoaded, setMovieLoaded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [version, setVersion] = useState(null);
  
  function isLoading() {
    return !movieLoaded || !imgLoaded;
  }
  
  function getCleanMovieData() {
    const { 
      languageSpans, 
      directorSpans, 
      writerSpans, 
      castSpans, 
      genreSpans, 
      ...cleanMovie } = movie;
    
    cleanMovie.mediaid = version ? version.mediaid : cleanMovie.mediaid;
    cleanMovie.version = version;
    
    return cleanMovie;
  }
  
  function fixDetailsLang(movieData, lang) {
    movieData.title = movieData.title[lang];
    movieData.plot = movieData.plot[lang];
    movieData.languageSpans = movieData.languages[lang].map((l, i) => <span className="mapped-data" key={`lang${i}`}>{l}</span>);
    movieData.directorSpans = movieData.directors.map((d, i) => <span className="mapped-data" key={`director${i}`}>{d}</span>);
    movieData.writerSpans = movieData.writers.map((w, i) => <span className="mapped-data" key={`writer${i}`}>{w}</span>);
    movieData.castSpans = movieData.cast.map((a, i) => <span className="mapped-data" key={`cast${i}`}>{a}</span>);
    movieData.genreSpans = Object.values(movieData.genres).map((g, i) => <span className="mapped-data" key={`genre${i}`}>{g.name[lang]}</span>);
    movieData.backdrop = movieData.backdrop || movieData.poster;
    movieData.imdbrating = typeof movieData.imdbrating == "number" ? movieData.imdbrating.toFixed(1) : movieData.imdbrating;
  }
  
  async function getMovieDetails() {
    try {
      const response = await axios.get(`/api/movies/details/${mediaID}`);
      const movieData = response.data;
      fixDetailsLang(movieData, i18n.language);
      setMovie(movieData);
      setMovieLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }
  
  function handleVersionChange(event) {
    const versionIndex = event.target.value;
    setVersion(movie.altversions[versionIndex] || null);
  }
  
  useEffect(() => {
    if (show && !movieLoaded) {
      getMovieDetails();
    }
    
    // When modal closes, version selection dropdown resets, but not the state.
    // Have to reset state manually.
    if (!show) {
      setVersion(null);
    }
  }, [show]);
  
  return (
    <Modal className="preview-modal" {...props} show={show} size="lg" centered animation={true}>
      <CommonLoader style={{ display: isLoading() ? 'block' : 'none' }} />
      <Modal.Body style={{ display: !isLoading() ? 'block' : 'none' }}>
        <Container className="preview-modal-container" fluid>
          <Row className="preview-modal-backdrop">
            <Image id={`${mediaID}-backdrop`} 
                   onLoad={() => setImgLoaded(true)} 
                   src={movie.backdrop} 
                   fluid />
            <div className="back-arrow" onClick={props.onHide}>
              <i className="fas fa-arrow-circle-left"></i>
            </div>
            <div className="on-backdrop-details">
              <i className="fas fa-star"></i>
              <span className="media-imdbrating">{ movie.imdbrating }</span>
              <i className="fas fa-circle"></i>
              <span className="media-year">{ movie.year }</span>
              <i className="fas fa-circle"></i>
              <span className="media-runtime">{ version ? version.runtime : movie.runtime } min</span>
              <i className="fas fa-circle"></i>
              <span className="media-languages">{ movie.languageSpans }</span>
            </div>
          </Row>
          <Row className="play-button-container">
            <Link to={{ pathname: '/watchmovie', state: getCleanMovieData() }}>
              <Button variant="secondary">
                <span className="play-button-text">{t('Watch')}</span>
              </Button>
            </Link>
          </Row>
          <Row className="media-title">
            <span>{ movie.title }</span>
          </Row>
          {movie.tags && movie.tags.length > 0 &&
            <Row className="movie-tags">
              {movie.tags.map((m, i) => <span key={`mod${i}`} className="movie-tag">{ m }</span>)}
            </Row>
          }
          {movie.altversions && movie.altversions.length > 0 && (
            <Row className="movie-versions">
              <Form onSubmit={e => e.preventDefault()}>
                <Form.Control as="select" onChange={handleVersionChange} defaultValue={-1}>
                  <option value={-1}>{t('TheatricalVersion')}</option>
                  {movie.altversions.map((v, i) => 
                    <option key={`altversion${i}`} value={i}>{v.name}</option>
                  )}
                </Form.Control>
              </Form>
            </Row>
          )}
          <Row className="media-plot">{ movie.plot || t('NoPlot') }</Row>
          {movie.directorSpans && movie.directorSpans.length > 0 &&
            <Row className="media-people-genres media-directors">
              <span className="details-section-title">{t('DirectorsTitle')}:</span>
              { movie.directorSpans }
            </Row>
          }
          {movie.writerSpans && movie.writerSpans.length > 0 &&
            <Row className="media-people-genres media-writers">
              <span className="details-section-title">{t('WritersTitle')}:</span>
              { movie.writerSpans }
            </Row>
          }
          {movie.castSpans && movie.castSpans.length > 0 &&
            <Row className="media-people-genres media-cast">
              <span className="details-section-title">{t('CastTitle')}:</span>
              { movie.castSpans }
            </Row>
          }
          <Row className="media-people-genres media-genres">
            <span className="details-section-title">{t('GenresTitle')}:</span>
            { movie.genreSpans }
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default MovieDetailsModal;
