import React, { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import CommonLoader from './CommonLoader';
import '../styles/InfiniteVScroll.scss';


function InfiniteVScroll({ pageStart, loadMore, loading, hasMore, children }) {
  const stateRef = useRef({
    page: pageStart,
    loadMore: loadMore,
    loading: loading,
    hasMore: hasMore
  });
  
  function scrollIsMax() {
    const scrollPos = window.innerHeight + window.scrollY;
    const scrollMax = document.body.offsetHeight;
    // Subtract max value by small amount, to avoid any floating point 
    // issues or browser differences in scroll position values.
    return scrollPos >= scrollMax - 50;
  }
  
  async function loadNextPage() {
    try {
      await loadMore(stateRef.current.page);
      stateRef.current.page++;
    } catch (err) {
      console.log('ScrollError:', err);
    }
  }
  
  function loadNextIfScrollMax() {
    const { page, loadMore, loading, hasMore } = stateRef.current;
    
    if (scrollIsMax() && hasMore && !loading) {
      loadNextPage();
    }
  }
  
  useEffect(() => {
    const handleScroll = debounce(loadNextIfScrollMax,  50);
    const handleResize = debounce(loadNextIfScrollMax, 100);
    
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Normally, each time any of the input props loadMore, loading or hasMore 
  // would change, we would have to recreate the event listeners to prevent 
  // stale state in closures. Instead, we use an imperative approach avoiding 
  // this, but at the cost of losing the purely functional component design.
  useEffect(() => {
    stateRef.current = {
      ...stateRef.current,
      loadMore: loadMore,
      loading: loading,
      hasMore: hasMore
    };
  }, [loadMore, hasMore, loading]);
  
  return (
    <div className="ivs-container">
      <div className="ivs-items-container">
        {children}
      </div>
      <div className="ivs-loader-container" style={{display: loading ? 'block' : 'none' }}>
        <CommonLoader />
      </div>
    </div>
  );
}


export default InfiniteVScroll;
