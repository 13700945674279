import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../styles/AppDownload.scss';

import Button from 'react-bootstrap/Button';


function AppDownload() {
  const { t } = useTranslation();
  const [version, setVersion] = useState(null);
  
  async function getVersion() {
    try {
      const response = await axios.get('/app/android/version');
      const { version } = response.data;
      setVersion(version);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getVersion();
  }, []);
  
  return (
    <div className="app-download-container ms-form-container">
      <h2 className="ms-form-header">{t('AppDownloadTitle')}</h2>
      <p>{t('AppDownloadDescription')}</p>
      <Button className="app-download-link" title={t('DownloadAppLinkTitle')} href="/app/android/download">{t('DownloadAppLinkText')}</Button>
      { version && <p className="app-version">{t('AppVersion', { version })}</p> }
    </div>
  );
}


export default AppDownload;
