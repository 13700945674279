import React, { useState } from 'react';
import MediaPreview from './MediaPreview';
import SeriesDetailsModal from './SeriesDetailsModal';


function SeriesPreview({ mediaID, posterURL, progress = null }) {
  return (
    <MediaPreview mediaID={mediaID} posterURL={posterURL} progress={progress} detailsModalComponent={SeriesDetailsModal} />
  );
}

export default SeriesPreview;
