import React, { useState, useEffect } from 'react';
import AllGenresContainer from './AllGenresContainer';
import MoviesGenreTop from './MoviesGenreTop';


function MoviesAllGenres({ selectGenres }) {
  return (
    <AllGenresContainer selectGenres={selectGenres} genreTopEndpoint="/api/movies/genretop" genreTopComponent={MoviesGenreTop} />
  );
}


export default MoviesAllGenres;
