import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../libs/context';
import Routes from './Routes';
import MixloNavbar from './MixloNavbar';
import '../styles/App.scss';


function App() {
  const [isAuthing, setIsAuthing] = useState(true);
  const [authedUser, setAuthedUser] = useState(null);
  
  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      const response = await axios.get('/api/user/authed');
      const { user } = response.data;
      if (user) {
        setAuthedUser(user);
      }
    } catch (err) {
      console.error(err.response.data);
    } finally {
      setIsAuthing(false);
    }
  }
  
  return (
    !isAuthing && (
      <AuthContext.Provider value={{ authedUser, setAuthedUser }}>
        <div className="bg-dark">
          {/* No strict mode for navbar, due to animation findDOMNode errors */}
          <MixloNavbar />
          <React.StrictMode>
            <Routes />
          </React.StrictMode>
        </div>
      </AuthContext.Provider>
    )
  );
}

export default App;
