import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MediaPage from './MediaPage';
import MoviesAllGenres from './MoviesAllGenres';
import MoviePreview from './MoviePreview';


function Movies() {
  const { t } = useTranslation();
  return (
    <MediaPage 
      title={t('Movies')} 
      genresEndpoint='/api/movies/genres' 
      searchEndpoint='/api/movies/search' 
      genresComponent={MoviesAllGenres} 
      previewComponent={MoviePreview} />
  );
}


export default Movies;
