import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GenreTopContainer from './GenreTopContainer';
import SeriesPreview from './SeriesPreview';


function SeriesGenreTop({ genre }) {
  const { t, i18n } = useTranslation();
  const pageLimit = 10;
  
  function seriesToPreviews(series) {
    return series.map(m => 
      <SeriesPreview key={m.mediaid} mediaID={m.mediaid} posterURL={m.poster} />
    );
  }
  
  async function getMoreSeries(page) {
    const skip = pageLimit * page;
    
    try {
      const response = await axios.get(`/api/series/findByGenre/${genre.gid}?skip=${skip}&limit=${pageLimit}`);
      const moreSeries = seriesToPreviews(response.data);
      return moreSeries;
    } catch (err) {
      console.error(err);
      alert(t('FailedToLoadMedia'));
      throw err;
    }
  }
  
  return (
    <GenreTopContainer 
      title={genre.name[i18n.language]} 
      initialData={seriesToPreviews(genre.series)} 
      getMoreMedia={getMoreSeries} />
  );
}


export default SeriesGenreTop;
