import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ChangeLanguage.scss';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';


function ChangeLanguage() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  
  function handleChange(lang) {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  }
  
  return (
    <div className="change-language-container ms-form-container">
      <h2 className="ms-form-header">{t('Language')}</h2>
      <ToggleButtonGroup className="language-select" name="languages" value={language} onChange={handleChange}>
        <ToggleButton value={'en-US'}>{t('English')}</ToggleButton>
        <ToggleButton value={'sv-SE'}>{t('Swedish')}</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}


export default ChangeLanguage;
