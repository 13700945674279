import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCGet } from '../libs/hooks';
import InfiniteVScroll from './InfiniteVScroll';
import CommonLoader from './CommonLoader';


function NoResults({ loading }) {
  const { t } = useTranslation();
  
  return (
    loading
      ? <CommonLoader />
      : <div><h4>{t('NoSearchResults')}</h4></div>
  );
}


function SearchResults({ query, searchEndpoint, previewComponent: Preview }) {
  const { t } = useTranslation();
  const pageLimit = 15;
  const [getSearchResults, searchLoading] = useCGet();
  const [previews, setPreviews] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  
  function getSearchUrl(page) {
    const skip = pageLimit * page;
    const searchUrl = `${searchEndpoint}?query=${query}&skip=${skip}&limit=${pageLimit}`;
    return searchUrl;
  }
  
  async function getMoreSearchResults(page) {
    try {
      const response = await getSearchResults(getSearchUrl(page));
      const morePreviews = response.data.map(media => 
        <Preview key={media.mediaid} mediaID={media.mediaid} posterURL={media.poster} progress={media.progress} />
      );
      
      setHasMore(response.data.length >= pageLimit);
      setPreviews(currentPreviews => [...currentPreviews, ...morePreviews]);
      setInitialLoading(false);
    } catch (err) {
      console.log('SearchError:', err.message || err);
    }
  }
  
  // Listens for change in input query
  useEffect(() => {
    if (query) {
      setInitialLoading(true);
      setPreviews([]);
      getMoreSearchResults(0);
    }
  }, [query]);
  
  return (
    <div className="search-results-container">
      <div className="search-results-header">
        <h2>{t('SearchResults')}:</h2>
      </div>
      <div className="search-results-contents">
        {previews.length === 0
          ? <NoResults loading={initialLoading} />
          : <InfiniteVScroll pageStart={1} 
                             loadMore={getMoreSearchResults} 
                             loading={searchLoading} 
                             hasMore={hasMore}>
              {previews}
            </InfiniteVScroll>
        }
      </div>
    </div>
  );
}


export default SearchResults;
