import React, { useState, useEffect } from 'react';
import { useCGet } from '../libs/hooks';
import CommonLoader from './CommonLoader';


function AllGenresContainer({ selectGenres, genreTopEndpoint, genreTopComponent: GenreTop }) {
  const [cGet, loading] = useCGet([]);
  const [genres, setGenres] = useState([]);
  
  useEffect(() => {
    async function getGenres() {
      try {
        const response = await cGet(genreTopEndpoint);
        setGenres(response.data);
      } catch (err) {
        console.log('AllGenresError:', err.message || err);
      }
    }
    
    getGenres();
  }, []);
  
  return (
    <div className="all-genres-container">
      {loading
        ? <CommonLoader />
        : genres
            .filter(g => selectGenres.length === 0 || selectGenres.includes(g.gid))
            .map(g => <GenreTop key={g.gid} genre={g} />)
      }
    </div>
  );
}


export default AllGenresContainer;
