import React from "react";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";


const cssOverride = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
`;

function CommonLoader(props) {
  return (
    <div {...props} className="media-loader">
      <PuffLoader css={cssOverride} size={100} />
    </div>
  );
}

export default CommonLoader;
