// All validation functions return the errors in form of a i18n key, so that 
// they can be appropriately translated in the form in which they are used.


function validateOldPassword(form) {
  const oldPass = form.elements.oldPassword;
  let error;
  
  if (oldPass.value === '') {
    error = 'OldPasswordRequired';
  } else {
    error = '';
  }
  
  oldPass.setCustomValidity(error);
  return error;
}


function validateNewPassword(form) {
  const newPass = form.elements.newPassword;
  const oldPass = form.elements.oldPassword;
  let error;
  
  if (newPass.value.length < 8) {
    error = 'NewPasswordMin8';
  } else if (newPass.value === oldPass.value) {
    error = 'NewPasswordCantBeOld';
  } else {
    error = '';
  }
  
  newPass.setCustomValidity(error);
  return error;
}


function validateConfPassword(form) {
  const newPass = form.elements.newPassword;
  const confPass = form.elements.confPassword;
  let error;
  
  if (confPass.value !== newPass.value) {
    error = 'PasswordsDontMatch';
  } else {
    error = '';
  }
  
  confPass.setCustomValidity(error);
  return error;
}


export { 
  validateOldPassword, 
  validateNewPassword,
  validateConfPassword
};
